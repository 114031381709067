import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import faq from "../../static/img/faq.png";
import {Accordion, AccordionItem} from "react-sanfona";
import showdown from "showdown";
import {useMediaQuery} from "react-responsive";

const converter = new showdown.Converter();
export const FaqPageTemplate = ({
                                  title,
                                  subtitle,
                                  description,
                                  faqSection,
                                }) => {
  const isTwoColumn = !useMediaQuery({query: '(max-width: 730px)'});
  let halfwayThrough = Math.ceil(faqSection.faqs.length / 2)

  let arrayFirstHalf = faqSection.faqs.slice(0, halfwayThrough);
  let arraySecondHalf = faqSection.faqs.slice(halfwayThrough, faqSection.faqs.length);
  return (
    <div>
      <div className="faq-page">
        <section
          className="mobile-faq-cover container "
        >
          <div className="mobile-view">
            <div className="column-1">
              <div className="faq-title">{title}</div>
              <div className="faq-subtitle">{subtitle}</div>
            </div>
            <div className="column-2">
              <div className="faq-hero-image">
                <img src={faq} alt="faq"/>
              </div>
              <div className="faq-description">{description}</div>
            </div>
          </div>
          <div className="desktop-view">
            <div className="column-1">
              <div className="faq-title">{title}</div>
              <div className="faq-subtitle">{subtitle}</div>
              <div className="faq-description">{description}</div>
            </div>
            <div className="column-2">
              <div className="faq-hero-image">
                <img src={faq} alt="faq"/>
              </div>
            </div>
          </div>
        </section>
        <section className="faq-section">
          <div className="sec-title">{faqSection.sectionTitle}</div>
          <div className={'columns container'}>
            <div className="questions">
              <Accordion className="faq-acc content" allowMultiple>
                {(isTwoColumn ? arrayFirstHalf : faqSection.faqs).map((item) => {
                  return (
                    <AccordionItem
                      expandedClassName={"faq-acc-item-expanded"}
                      bodyClassName={"faq-acc-item-body"}
                      className="faq-acc-item"
                      title={item.question}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(item.answer),
                        }}
                      />
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
            {isTwoColumn && <div className="questions">
              <Accordion className="faq-acc content" allowMultiple>
                {arraySecondHalf.map((item) => {
                  return (
                    <AccordionItem
                      expandedClassName={"faq-acc-item-expanded"}
                      bodyClassName={"faq-acc-item-body"}
                      className="faq-acc-item"
                      title={item.question}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(item.answer),
                        }}
                      />
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>}
          </div>
        </section>
      </div>
    </div>
  );
};

const FaqPage = ({data}) => {
  const {frontmatter} = data.markdown;
  return (
    <Layout>
      <FaqPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        description={frontmatter.description}
        faqSection={frontmatter.faqSection}
      />
    </Layout>
  );
};

FaqPage.prototypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default FaqPage;

export const pageQuery = graphql`
  query FaqPageTemplate {
    markdown: markdownRemark(frontmatter: { templateKey: { eq: "faq" } }) {
      frontmatter {
        title
        subtitle
        description
        faqSection {
          sectionTitle
          faqs {
            question
            answer
          }
        }
      }
    }
  }
`;
